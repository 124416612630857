<template>
  <div id="lesson-management">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5">
          <h2 class="d-flex mb-5">
            <v-icon @click="back()">arrow_back_ios</v-icon>
            <div class="d-flex align-center">Student search</div>
          </h2>

          <p v-if="studentList.length > 1" class="mb-5">Total: {{ studentList.length }} students</p>
          <p v-else class="mb-5">Total: {{ studentList.length }} student</p>
          <div class="pb-10 mb-10">
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="primary"
              class="my-10"
            ></v-progress-linear>
            <v-data-table
              v-if="!isLoading"
              :headers="headers"
              :items="filtered"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              hide-default-footer
              class="elevation-1"
              fixed-header
              height="60vh"
            >
              <template v-slot:header="{ props }">
                <tr class="fixed-row-filter header-outstanding">
                  <th v-for="header in props.headers" :key="header.text" class="headerOueding">
                    <div v-if="filters.hasOwnProperty(header.value)">
                      <Autocomplete
                        v-if="filters.hasOwnProperty(header.value)"
                        @setFilter="setFilter"
                        :styleBorder="'border-bottom'"
                        :keyFilter="header.value"
                        :selectedInit="filters[header.value]"
                        :listItem="groupColumnValueList(header.value)"
                      />
                    </div>
                  </th>
                </tr>
              </template>
              <template v-slot:[`item.no`]="{ index }">
                {{ itemsPerPage * (page - 1) + index + 1 }}
              </template>
              <template v-slot:[`item.studentCode`]="{ item }">
                <span class="link" @click="viewProfile(item.stu_UserID)">
                  {{ item.studentCode }}
                </span>
              </template>
              <template v-slot:[`item.studentName`]="{ item }">
                <span class="link" @click="viewProfile(item.stu_UserID)">
                  {{ item.studentName }}
                </span>
              </template>
              <template v-slot:[`item.classCode`]="{ item }">
                <span class="link" @click="viewClassDetail(item.classID)">
                  {{ item.classCode }}
                </span>
              </template>
              <template v-slot:[`item.ecName`]="{ item }">
                <span class="link" @click="viewProfile(item.ec_userID)"> {{ item.ecName }} </span>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-footer
      color="#fff"
      class="px-10 pb-2"
      padless
      :style="
        $store.state.isActiveSidebar ? 'width: calc(100% - 100px)' : 'width: calc(100% - 258px)'
      "
      style="display: flex; justify-content: space-between; z-index: 8"
    >
      <div>
        <v-select
          outlined
          hide-details
          dense
          :value="itemsPerPage"
          :items="[20, 50, 100, 200]"
          @change="itemsPerPage = parseInt($event, 10)"
          style="height: 32px"
        ></v-select>
      </div>
      <v-alert class="mb-10" dense transition="scale-transition" :value="userNull" type="error"
        >Undefined user !</v-alert
      >
      <div>
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
          elevation="0"
        ></v-pagination>
      </div>
    </v-footer>
  </div>
</template>

<script>
import Autocomplete from '@/components/Utils/Autocomplete.vue'

import { student_information } from '@/api/student-information.js'
import dayjs from 'dayjs'
export default {
  components: { Autocomplete },
  data() {
    return {
      studentName: this.$route.query.studentName,
      studentCode: this.$route.query.studentCode,
      studentPhoneNum: this.$route.query.studentPhoneNum,
      studentClass: this.$route.query.studentClass,
      isLoading: false,
      studentList: [],
      userNull: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,

      headers: [
        {
          text: 'No',
          align: 'start',
          value: 'no',
          sortable: false,
        },
        { text: 'Student code', value: 'studentCode', sortable: false },
        { text: 'Student name', value: 'studentName', sortable: false, width: '150px' },
        { text: 'Current class', value: 'classCode', sortable: false, width: '150px' },
        { text: 'Remark', value: 'remark', sortable: false, width: '300px' },
        { text: 'EC', value: 'ecName', sortable: false, width: '150px' },
        { text: 'DOB', value: 'dob', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Mobile phone', value: 'mobilePhone', sortable: false },
        { text: 'Mother name', value: 'motherName', sortable: false, width: '150px' },
        { text: 'Mother phone', value: 'motherPhone', sortable: false },
        {
          text: 'Father name',
          width: '150px',
          value: 'fatherName',
          sortable: false,
        },
        { text: 'Father phone', value: 'fatherPhone', sortable: false },
        { text: 'Center', value: 'center', sortable: false, width: '150px' },
      ],
      filters: {
        studentCode: [],
        studentName: [],
        classCode: [],
        remark: [],
        ecName: [],
        dob: [],
        email: [],
        mobilePhone: [],
        motherName: [],
        motherPhone: [],
        fatherName: [],
        fatherPhone: [],
        center: [],
      },
    }
  },
  computed: {
    filtered() {
      return this.studentList.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
  },
  watch: {
    '$route.query': {
      handler: function () {
        this.getStudentList()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getStudentList()
  },
  methods: {
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('studentSearchFilter', JSON.stringify(this.filters))
    },
    groupColumnValueList(val) {
      return this.studentList.map(d => d[val]).filter(y => y)
    },
    async getStudentList() {
      if (JSON.parse(localStorage.getItem('searchStudent'))) {
        const student = JSON.parse(localStorage.getItem('searchStudent'))
        this.studentName = student.studentName
        this.studentCode = student.studentCode
        this.studentPhone = Number(student.studentPhoneNum)
        this.studentClass = student.studentClass
      }
      this.isLoading = true
      await student_information
        .getStudentSearch(
          this.studentName,
          this.studentCode,
          Number(this.studentPhone),
          this.studentClass
        )
        .then(res => {
          this.studentList = res.map(student => this.formatStudentInfo(student))
          this.isLoading = false
        })
    },

    formatStudentInfo(student) {
      Object.keys(student).forEach(attr => {
        if (
          (student[attr] == '' || student[attr] == null || student[attr] == 0) &&
          attr != 'stu_UserID'
        ) {
          student[attr] = '-'
        }
      })
      return {
        ...student,
        dob: dayjs(student.dob).format('DD/MM/YYYY'),
      }
    },
    viewClassDetail(classID) {
      this.$router.push({
        name: 'class-management-detail',
        query: { classID: classID },
      })
    },
    viewProfile(userId) {
      if (userId) {
        this.$router.push({
          name: 'account-management-view-account',
          params: {
            userId: userId,
          },
        })
      } else {
        this.userNull = true
        setTimeout(() => {
          this.userNull = false
        }, 1000)
      }
    },
    back() {
      window.history.back()
    },
    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  color: #427df2 !important;
}

.link:hover {
  text-decoration: underline;
  font-weight: 700;
}
</style>
