var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"lesson-management"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-10 pt-5"},[_c('h2',{staticClass:"d-flex mb-5"},[_c('v-icon',{on:{"click":function($event){return _vm.back()}}},[_vm._v("arrow_back_ios")]),_c('div',{staticClass:"d-flex align-center"},[_vm._v("Student search")])],1),(_vm.studentList.length > 1)?_c('p',{staticClass:"mb-5"},[_vm._v("Total: "+_vm._s(_vm.studentList.length)+" students")]):_c('p',{staticClass:"mb-5"},[_vm._v("Total: "+_vm._s(_vm.studentList.length)+" student")]),_c('div',{staticClass:"pb-10 mb-10"},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filtered,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","fixed-header":"","height":"60vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',{staticClass:"fixed-row-filter header-outstanding"},_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"headerOueding"},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.itemsPerPage * (_vm.page - 1) + index + 1)+" ")]}},{key:"item.studentCode",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.viewProfile(item.stu_UserID)}}},[_vm._v(" "+_vm._s(item.studentCode)+" ")])]}},{key:"item.studentName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.viewProfile(item.stu_UserID)}}},[_vm._v(" "+_vm._s(item.studentName)+" ")])]}},{key:"item.classCode",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.viewClassDetail(item.classID)}}},[_vm._v(" "+_vm._s(item.classCode)+" ")])]}},{key:"item.ecName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.viewProfile(item.ec_userID)}}},[_vm._v(" "+_vm._s(item.ecName)+" ")])]}}],null,true)}):_vm._e()],1)])])],1),_c('v-footer',{staticClass:"px-10 pb-2",staticStyle:{"display":"flex","justify-content":"space-between","z-index":"8"},style:(_vm.$store.state.isActiveSidebar ? 'width: calc(100% - 100px)' : 'width: calc(100% - 258px)'),attrs:{"color":"#fff","padless":""}},[_c('div',[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-alert',{staticClass:"mb-10",attrs:{"dense":"","transition":"scale-transition","value":_vm.userNull,"type":"error"}},[_vm._v("Undefined user !")]),_c('div',[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }